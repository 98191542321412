import React from "react";
import "./styles.css";

// Render function for Prismic headless CMS pages
function VectorContent(props) {
  const { videoUrl, audioUrl } = props;
  console.log(videoUrl, audioUrl);
  return (
    <div className="video-responsive1">
      <iframe
        width="853"
        height="480"
        className="iframe-loader"
        src={`https://mudita.ai/l3SwPlWdnnxeqiwJzStV/?audio=${audioUrl}&video=${videoUrl}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded Vector video"
        onLoad={() => {
          document.getElementsByClassName("iframe-loader")[0].style.background =
            "None";
        }}
      />
    </div>
  );
}

export default VectorContent;
