import React from "react";
import "./styles.css";

// Render function for Prismic headless CMS pages
function GoogleContent(props) {
  const { url } = props;
  let type = "drive";
  function getIdFromUrl(url) {
    if (url.match(/forms/)) {
      type = "forms";
      return url;
    } else {
      return url.match(/[-\w]{25,}/);
    }
  }

  let URL = getIdFromUrl(url);

  return (
    <div className="video-responsive1">
      <iframe
        width="853"
        height="480"
        className="iframe-loader"
        src={
          type === "drive"
            ? `https://docs.google.com/gview?a=v&pid=explorer&chrome=false&api=true&embedded=true&srcid=${URL[0]}&hl=en&embedded=true`
            : URL
        }
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded Google doc"
        onLoad={() => {
          document.getElementsByClassName("iframe-loader")[0].style.background =
            "None";
        }}
      />
    </div>
  );
}

export default GoogleContent;
