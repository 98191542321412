import React from "react";
import "./styles.css";

// Render function for Prismic headless CMS pages
function YoutubeContent(props) {
  const { videoUrl } = props;
  let videoCode;
  if (videoUrl) {
    videoCode = videoUrl.split("v=")[1].split("&")[0];
  }

  return (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        className="iframe-loader"
        src={`https://www.youtube.com/embed/${videoCode}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        onLoad={() => {
          document.getElementsByClassName("iframe-loader")[0].style.background =
            "None";
        }}
      />
    </div>
  );
}

export default YoutubeContent;
