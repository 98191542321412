import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import { BASE_URL } from "./constants";
import GoogleContent from "./GoogleContent";
import VectorContent from "./VectorContent";
import YoutubeContent from "./YoutubeContent";

function App(props) {
  console.log(props);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState();
  useEffect(() => {
    fetch(BASE_URL + "/video-content?url_hash=" + params.ID, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Success:", result);
        setPayload(result.payload);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [params]);

  return loading ? (
    <div className="loader"></div>
  ) : payload && payload.type ? (
    <div>
      {payload.type === "youtube" && (
        <YoutubeContent videoUrl={payload.file_url} />
      )}
      {payload.type === "google" && <GoogleContent url={payload.file_url} />}
      {payload.type === "vector" && payload.video && payload.audio && (
        <VectorContent
          videoUrl={BASE_URL + "/files/" + payload.video}
          audioUrl={BASE_URL + "/files/" + payload.audio}
        />
      )}
    </div>
  ) : (
    payload && <h1>{payload}</h1>
  );
}

export default App;
