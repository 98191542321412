//base URL
export let BASE_URL;
export let CLIENT_URL;

if (process.env.NODE_ENV === "production") {
  // BASE_URL = "https://sl-test.mudita.app/kaT4en6GNaQsCccFlWwE/api";
  BASE_URL = "https://mudita.ai/kaT4en6GNaQsCccFlWwE/api";
} else {
  BASE_URL = "http://localhost:5000";
}
